
<template>
  <div class="seting-box">
    <s-header :name="$t('t.shareProfits')"></s-header>
    <van-row class="color24 backF5 paddTop60">
      <van-tabs v-model="tabActive">
        <van-tab name="1" :title="$t('t.shareProfits')">
          <van-row class="backF padding15 size14 marTop15" type="flex" align="center" justify="space-between">
            <van-row class="tle">{{$t('t.invitationLink')}}</van-row>
            <van-button size="small" round  color="#e92d74" class="paddLR20" @click="doCopy">{{$t('t.copy')}}</van-button>
          </van-row>
          <van-row class="padding15">
            <van-row class="size14">{{$t('t.invitationStep')}}：<span class="size12">（{{$t('t.chooseOne')}}）</span></van-row>
            <van-row class="marTop10">{{$t('t.tips1')}}</van-row>
            <van-row class="marTop10">{{$t('t.tips2')}}</van-row>
          </van-row>
          <van-row class="colorTheme lineH15 backF padding10">
            <van-icon name="fire" class="size16" />
            <van-icon name="fire" class="size16" />
            <van-icon name="fire" class="size16" />
            {{$t('t.tips')}}
          </van-row>
          <van-button size="small" block  color="#e92d74" class="paddLR20" @click="toImage1">{{$t('t.posters')}}</van-button>
          <van-swipe :loop="false" :width="300" ref="swiperRef" class="paddBott30">
            <van-swipe-item>
              <div class="relative padding20" ref="qrUrlImg0">
                <img class="app height100" src="../assets/image/share1.jpg" alt="">
                <vue-qr class="qrBox" v-if="qrCodeImg" :margin="5" colorLight="#fff" :text="qrCodeImg" :size="qrSize"></vue-qr>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="relative padding20" ref="qrUrlImg1">
                <img class="app height100" src="../assets/image/share2.jpg" alt="">
                <vue-qr class="qrBox" v-if="qrCodeImg" :margin="5" colorLight="#fff" :text="qrCodeImg" :size="qrSize"></vue-qr>
              </div>
            </van-swipe-item>
            <!--<van-swipe-item>-->
              <!--<div class="relative padding20" ref="qrUrlImg2">-->
                <!--<img class="app height100" src="../assets/image/share1.png" alt="">-->
                <!--<vue-qr class="qrBox" v-if="qrCodeImg" :margin="5" colorLight="#fff" :text="qrCodeImg" :size="qrSize"></vue-qr>-->
              <!--</div>-->
            <!--</van-swipe-item>-->
            <!--<van-swipe-item>-->
              <!--<div class="relative padding20" ref="qrUrlImg3">-->
                <!--<img class="app height100" src="../assets/image/share2.jpg" alt="">-->
                <!--<vue-qr class="qrBox" v-if="qrCodeImg" :margin="5" colorLight="#fff" :text="qrCodeImg" :size="qrSize"></vue-qr>-->
              <!--</div>-->
            <!--</van-swipe-item>-->
          </van-swipe>
        </van-tab>
        <van-tab name="2" :title="$t('t.bonusDetail')">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="listBox overflowY">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="$t('t.noMore')"
              @load="onLoad"
              @offset="300">
              <van-row v-for="(item, i) in commissionList" :key="i" class="padding15 marBott10 backF">
                <van-row type="flex" align="center" justify="space-between">
                  <van-row>{{$t('t.orderNumber')}}：{{item.orderNo}}</van-row>
                  <van-row class="colorBB">{{item.createTime }}</van-row>
                </van-row>
                <van-row class="marTop10">{{$t('t.totalContribution')}}：<span class="colorTheme fontB size14">{{item.parentTotalCommission?item.parentTotalCommission:item.grandParentTotalCommission }}</span></van-row>
              </van-row>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>

    </van-row>
    <van-dialog v-model="imgShowBool" :title="$t('t.saveScreenshot')" :cancel-button-text="$t('t.cancel')" :show-confirm-button="false" show-cancel-button>
      <div class="overflowY height70Vh">
        <img class="app" :src="imgUrl" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import { getUserInfo,getCommissionList,getCommissionOrderList} from '../service/user'
  import html2canvas from "html2canvas"
  import vueQr from 'vue-qr'
  import sHeader from '@/components/SimpleHeader'
  import { doCopy } from '@/common/js/utils'
  export default {
    components: {
      sHeader,
      vueQr
    },
    data() {
      return {
        tabActive:'1',
        qrCodeImg:'',
        imgShowBool:false,
        imgUrl:'',
        qrSize:55,
        userInfo:'',

        refreshing: false,
        loading: false,
        finished: false,
        commissionList: [],
        totalPage: 0,
        page: 1,

      }
    },
    async mounted() {
      const { data } = await getUserInfo()
      this.userInfo = data
      let urlStr = `https://${window.location.host}/#/register?invitationCode=${data.userId}`
      this.qrCodeImg = urlStr
    },
    methods: {
      doCopy(){
        doCopy(this.qrCodeImg)
      },
      toImage1() {
        let qrUrlImgRef = 'qrUrlImg'+this.$refs['swiperRef'].active
        window.pageYOffset = document.body.scrollHeight
        document.documentElement.scrollTop = document.body.scrollHeight
        document.body.scrollTop = document.body.scrollHeight
        this.imgUrl = ''
        var shareContent = this.$refs[qrUrlImgRef]//document.getElementById("generPic");//需要截图的包裹的（原生的）DOM 对象
        html2canvas(shareContent).then(canvas => {
          let dataURL = canvas.toDataURL("image/jpg");
          this.imgUrl = dataURL
          this.imgShowBool = true
        });
      },
      async init() {
        const { data, data: { list } } = await getCommissionOrderList({ pageNumber: this.page})
        this.commissionList = this.commissionList.concat(list)
        this.totalPage = data.totalPage
        this.loading = false;
        if (this.page >= data.totalPage) this.finished = true
      },
      onLoad() {
        if (!this.refreshing && this.page < this.totalPage) {
          this.page = this.page + 1
        }
        if (this.refreshing) {
          this.commissionList = [];
          this.refreshing = false;
        }
        this.init()
      },
      onRefresh() {
        this.refreshing = true
        this.finished = false
        this.loading = true
        this.page = 1
        this.onLoad()
      },
    }
  }
</script>

<style lang="less" scoped>
  .seting-box {
    .input-item {
      margin-top: 44px;
    }
    .save-btn {
      width: 80%;
      margin: 20px auto ;
    }
  }
  .paddTop60{
    padding-top: 50px;
  }
  .qrBox{
    position: absolute;
    left: 43px;
    bottom: 80px;
  }
  .listBox{
    margin-top: 20px;
    height: calc(100vh - 60px);;
  }
</style>
