
<template>
  <div class="seting-box">
    <s-header :name="$t('t.moneyDoingMissions')"></s-header>
    <van-row class="color24 backF5 paddTop60 listBox">
      <van-row class="backF padding15 size14 marTop10">
        <van-row class="tle size18 fontB borderButtE4 paddBott15">{{$t('t.doTaskCollectGift')}}</van-row>
        <van-row class="size14 marTop15 color24">① {{$t('t.taskStep')}}：</van-row>
        <!--<van-row class="marTop10 size12 color77">1.点击下方<span class="fontB colorTheme paddLR5">“领取任务”</span>按钮</van-row>-->
        <van-row class="marTop10 size12 color77" type="flex" align="middle">1.{{$t('t.goToProductDetailsAny')}}，{{$t('t.clickTopRight')}}
          <van-icon name="link-o" class="size20 colorW fontB" />{{$t('t.icon')}}
        </van-row>
        <van-row class="marTop10 size12 color77 borderButtE4 paddBott15 lineH15">2.{{$t('t.copyProductDetailsLink')}}，
          {{$t('t.sendYourFriends')}}<span class="fontB colorTheme paddLR5">{{$t('t.view')}}</span>，{{$t('t.canObtainCorresponding')}}
          <span class="fontB colorTheme paddLR5">{{$t('t.rewards')}}</span></van-row>

        <van-row class="size14 marTop15 color24">① {{$t('t.taskReward')}}：</van-row>
        <van-row class="marTop10 size12 color77"
                 v-for="(item,i) in taskObj.taskConfigParam.taskConfigParamList" :key="i">
          {{i+1}}.{{$t('t.be')}}<span class="fontB colorG paddLR5">{{item.maxNum}}</span> {{$t('t.personView')}}，{{$t('t.gain')}}<span class="fontB colorTheme paddLR5">{{item.remark}}</span>
        </van-row>
        <van-row class="marTop10 size12 colorG fontB">{{$t('t.note')}}，{{$t('t.moreRewards')}}</van-row>

        <!--<div class="button"><span>开启</span></div>-->
        <!--<van-row type="flex" align="center" justify="center" class="marTop30">-->
          <!--<div class="btn">领取任务</div>-->
          <!--<div class="btn_animation"></div>-->
        <!--</van-row>-->
        <van-row class="size14 marTop15 color24 marBott15">③ {{$t('t.countdownMission')}}：</van-row>
        <van-row class="countDownBox  padding15" type="flex" align="center" justify="center">
          <van-count-down :time="time" >
            <template #default="timeData">
            <van-row type="flex" align="center" class="colorTheme size18">
              <van-row class="paddLR10 fontB">{{ timeData.days }}</van-row>
              {{$t('t.day')}}
              <van-row class="paddLR10 fontB">{{ timeData.hours}}</van-row>
              {{$t('t.when')}}
              <van-row class="paddLR10 fontB">{{ timeData.minutes}}</van-row>
              {{$t('t.points')}}
              <van-row class="paddLR10 fontB">{{ timeData.seconds}}</van-row>
              {{$t('t.seconds')}}
            </van-row>
            </template>
          </van-count-down>
        </van-row>

        <van-row
          class="size14 marTop20 color24"
          type="flex" align="center">
          ③ {{$t('t.taskProgress')}}：
          <span class="color77 size12">（{{$t('t.currentlyCompletedNumber')}}：
              <span class="fontB colorTheme paddLR5 size16">{{taskObj.num}}</span>）
          </span>

        </van-row>
        <van-row class="paddTop15 relative progressBox app">
          <van-row type="flex" align="center" class="app height100">
            <van-row
              v-for="(item,i) in taskObj.taskConfigParam.taskConfigParamList" :key="i"
              class="flex1 marRight10 box flexDirection"
              type="flex" align="center">
              <van-row class="size12 marTop20"><span class="fontB colorG">{{item.maxNum>9999?item.maxNum/10000+'W+':item.maxNum}}</span> 人查看</van-row>
              <van-button
                :type="taskObj.num>item.maxNum?'primary':''"
                :disabled="taskObj.num>item.maxNum?false:true"
                :color="taskObj.num<item.maxNum?'#aaa':''"
                size="small" class="marTop60" @click="taskWithdrawal(item.maxNum)">{{$t('t.rewards')}}</van-button>
            </van-row>
          </van-row>
          <van-row type="flex" align="center" justify="space-between" class="okBox">
            <van-icon
              v-for="(item,i) in taskObj.taskConfigParam.taskConfigParamList" :key="i"
              :class="taskObj.num>item.maxNum?'colorG':'colorBB'"
              name="checked" class="size18" />
          </van-row>
          <van-slider class="sliderBox" v-model="sliderVal" bar-height="12px" disabled active-color="#e92d74" >
            <template #button>
              <div class="custom-button">{{ sliderText }}</div>
            </template>
          </van-slider>

        </van-row>


      </van-row>

    </van-row>

  </div>
</template>


<script>
  import { taskSelect,taskWithdrawal} from '../service/user'
  import sHeader from '@/components/SimpleHeader'
  import math from '../common/js/calculationAccuracy'
  export default {
    components: {
      sHeader,
    },
    data() {
      return {
        sliderVal:12.8,
        sliderText:400,
        time:30 * 60 * 60 * 1000,
        taskObj:"",
      }
    },
    async mounted() {
      this.taskSelect()
    },
    methods: {
      // calcFn(val){
      //   this.sliderText = 9
      //   let one = 100//Number(this.taskObj.taskConfigParam.taskConfigParamList[0].maxNum)
      //   let two = 1000//Number(this.taskObj.taskConfigParam.taskConfigParamList[1].maxNum)
      //   let there = 10000//Number(this.taskObj.taskConfigParam.taskConfigParamList[2].maxNum)
      //   let four = 100000//Number(this.taskObj.taskConfigParam.taskConfigParamList[3].maxNum)
      //   val = Number(val)
      //   if(val<=one){
      //     this.sliderVal = math.multiply(100/7,val/one)
      //   }else if(val<two){
      //     let x = (two-one)*7/200
      //     let two = math.divide((val-one),x)
      //     this.sliderVal = math.add(100/7,two)
      //   }else if(val<there){
      //     let x = (there-two)*7/200
      //     let there = math.divide((val-two),x)
      //     this.sliderVal = math.add(100/7,200/7,there)
      //   }else{
      //     let x = (four-there)*7/200
      //     let four = math.divide((val-there),x)
      //     this.sliderVal = math.add(100/7,200/7,200/7,four)
      //   }
      //   console.log(this.sliderVal)
      // },
      async taskSelect(){
        const { data } = await taskSelect()
        this.taskObj = data
        // this.taskObj.num = 9
        this.sliderText = data.num
        this.sliderVal = data.schedule

        if(data.taskConfigParam.endDate) {
          this.time = new Date(data.taskConfigParam.endDate).getTime() - Date.now()
        }
      },
      async taskWithdrawal(maxNum){
        if(this.taskObj.maxNumList && this.taskObj.maxNumList.includes(maxNum)){
          this.$toast(this.$t('t.notReapply'))
        }else{
          const { resultCode } = await taskWithdrawal({maxNum})
          if (resultCode == 200 ) {
            this.taskSelect()
            this.$toast.success(this.$t('t.applicationSuccessful'))
          }
        }

      }
    }
  }
</script>

<style lang="less" scoped>
  @import '../common/style/mixin';
  .paddTop60{
    padding-top: 50px;
  }
  .listBox{
    /*height: calc(100vh - 60px);;*/
  }

  .btn{
    width: 80px;
    height: 80px;
    /*background-color: #2979ff;*/
    /*background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));*/
    background: radial-gradient(circle at top center, #f28fb8, #e982ad, #ec568c);
    border-radius: 50%;
    position: absolute;
    text-align: center;
    line-height: 80px;
    color: #fff;
    font-size: 16px;
  }
  .btn:active{
    text-shadow:0 3px 10px #f1a2c1,0 -3px 10px #f1a2c1;
    background-color: #ecf5ff;
  }
  .btn_animation{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f1a2c1;
    animation: scale 1.5s infinite cubic-bezier(0,0,0.49,1.02);
  }
  @keyframes scale {
    0%{
      transform: scale(1);
    }
    50%, 75%{
      transform: scale(1.2);
    }
    78%, 100%{
      opacity: 0;
    }
  }



  .progressBox{
    height: 150px;
  }
  .sliderBox{
    position: absolute;
    top: 80px;
    left: 0;
    width: 90%;
    z-index: 10;
  }
  .box{
    border: 1px dashed #FF6B01;
    height: 100%;
    z-index: 1;
  }

  .custom-button {
    color: #fff;
    font-size: 10px;
    /*padding: 3px 3px;*/
    text-align: center;
    background-color: @theme;
    border-radius: 100px;
  }
  .marTop60{
    margin-top: 60px;
  }
  .okBox{
    margin-left: 10%;
    width: 80%;
    position: absolute;
    z-index: 18;
    top:77px;
  }
  .countDownBox{
    border: 1px dashed @theme;
  }
</style>
